body {
  margin: 0;
  overflow-x: hidden;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 60px;
}

.App-header {
  background-color: #282c34;
  padding: 10px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  border-bottom: 2px solid #61dafb;
}

.logo-container {
  flex: 0 0 auto;
}

.nav-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%; /* This ensures the container spans the entire width */
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

nav ul li {
  display: flex;
  align-items: center;
}

nav ul li + li::before {
  content: '|';
  margin: 0 15px;
  color: white;
  vertical-align: middle;
  font-size: 18px;
}

nav ul li a {
  color: #61dafb;
  text-decoration: none;
  font-size: 18px;
  vertical-align: middle;
}

nav ul {
  position: relative;
}

nav ul li:nth-child(2) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.App-content {
  background-color: #1c1e22;
  color: white;
  padding: 100px 20px 20px 20px; /* Increased padding-top to ensure content is not hidden behind the header */
  flex: 1;
}

.App-footer {
  background-color: #20232a;
  color: white;
  padding: 10px;
  border-top: 2px solid #61dafb;
}
